// import App from 'next/app'
import {useEffect, useState} from 'react'
import Head from 'next/head'
import {useRouter} from 'next/router'
import {useSessionTrack} from '../hooks/useSessionTrack'
import {UserContext, GlobalContext} from '../contexts'
import {debugLog, persistAuthUserLocally} from '../utils'
import '@reach/dialog/styles.css'
import 'react-toastify/dist/ReactToastify.css'
import 'hint.css/hint.min.css'
import '../style.css'
import '../responsive.css'
import {ToastContainer, Slide} from 'react-toastify'
import {
  getPlayerProfile,
  getStreakCountRealtime,
  getUserSettings,
  saveProfile,
} from 'src/util/db'
import {useWhenMounted} from 'src/hooks/useWhenMounted'
import {CommandPalette} from 'src/components/CommandPalette'
import {sendEvent} from 'src/analytics'
import {Page} from 'src/Page'
import {auth} from '../firebase-init'
import {onAuthStateChanged} from 'firebase/auth'
import mixpanel from 'mixpanel-browser'

if (typeof window !== 'undefined') {
  const u = window.localStorage.getItem('lastUser')
  try {
    const parsedUser = JSON.parse(u)
    if (parsedUser && parsedUser?.settings?.theme === 'light') {
      const s = document.createElement('link')
      s.href = '/light-theme.css'
      s.rel = 'stylesheet'
      s.id = 'lightThemeLink'
      document.head.append(s)
    }
  } catch (e) {}
}

function MyApp({Component, pageProps}) {
  const router = useRouter()
  const [user, setUser] = useState(() => {
    if (typeof window === 'undefined') return undefined

    // User 'undefined' means we have not yet tried reading from localStorage.
    // User 'null' means we read from localStorage but found no saved user.
    const savedUser = window.localStorage.getItem('lastUser')
    const parsedUser = JSON.parse(savedUser)
    if (parsedUser) {
      parsedUser.gameMode = !!window.localStorage.getItem('gamemode')
    }
    return savedUser ? parsedUser : null
  })
  const [isPaletteOpen, setIsPaletteOpen] = useState(false)

  const isMounted = useWhenMounted()

  useEffect(() => {
    console.log(`
 _______  _______  _______  _______  _______  _______  _______  ___      _______ 
|       ||       ||       ||  _    ||   _   ||       ||       ||   |    |       |
|       ||  _____||  _____|| |_|   ||  |_|  ||_     _||_     _||   |    |    ___|
|       || |_____ | |_____ |       ||       |  |   |    |   |  |   |    |   |___ 
|      _||_____  ||_____  ||  _   | |       |  |   |    |   |  |   |___ |    ___|
|     |_  _____| | _____| || |_|   ||   _   |  |   |    |   |  |       ||   |___ 
|_______||_______||_______||_______||__| |__|  |___|    |___|  |_______||_______|
`)
    console.log(
      '{⚔️} Join our community forum -> https://community.cssbattle.dev'
    )
  }, [])

  useSessionTrack()

  useEffect(() => {
    window.dataLayer = window.dataLayer || []

    window.gtag = function gtag() {
      dataLayer.push(arguments)
    }
    gtag('js', new Date())

    if (!location.href.match(/localhost/)) {
      let s = document.createElement('script')
      s.src = 'https://www.googletagmanager.com/gtag/js?id=G-3TQQFVS3DE'
      s.async = true
      document.body.appendChild(s)
    }

    gtag('config', 'G-3TQQFVS3DE')
    gtag('config', 'AW-862376687')
  }, [])

  useEffect(() => {
    onAuthStateChanged(auth, async function (authUser) {
      debugLog('auth change', user, authUser)
      if (authUser) {
        // User is signed in.
        // persistAuthUserLocally(authUser)
        mixpanel.identify(authUser.uid)

        let newUser = {
          uid: authUser.uid,
          photoURL: authUser.photoURL,
        }
        // port some keys from localstorage user to new auth user
        const keysToPort = [
          'isPro',
          'displayName',
          'settings',
          'gameMode',
          'userSettings',
        ]
        keysToPort.forEach((key) => {
          if (user && user[key] !== undefined) {
            newUser[key] = user[key]
          }
        })
        // storing actual firebase user object for accessing functions like updateProfile
        newUser.firebaseUser = authUser

        setUser(newUser)

        const [userSettings, profile] = await Promise.all([
          getUserSettings(authUser.uid),
          getPlayerProfile(authUser.uid, true),
        ])

        // console.log('player profile', profile, authUser)
        mixpanel.people.set({pro: profile?.isPro})

        // spreading authUser doesn't work below anymore because the required properties are
        // not enumerable anymore
        newUser = {
          ...newUser,
          isPro: false,
          ...profile,
          userSettings,
        }

        // Setting in localstorage before setting `firebaseUser` key,
        // otherwise it will become a circular loop
        persistAuthUserLocally(newUser)

        setUser(newUser)

        // if we find keys in localstorage for pending email subscription, we need to save it
        // in the db
        const pendingEmailKeys = [
          {
            lsKey: 'pendingEmailSubscription',
            dbKey: 'settings.emailNotifications',
          },
          {
            lsKey: 'pendingNewsletterEmailSubscription',
            dbKey: 'settings.newsletterMailNotifications',
          },
        ]
        pendingEmailKeys.forEach(({lsKey, dbKey}) => {
          const lsValue = window.localStorage.getItem(lsKey)
          if (lsValue) {
            saveProfile(newUser.uid, {
              [dbKey]: true,
            })
            window.localStorage.removeItem(lsKey)
          }
        })
      } else {
        window.localStorage.removeItem('lastUser')
        setUser(null)
      }
    })
  }, [])

  useEffect(() => {
    const keydownHandler = (e) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
        //toggle palette on command + k
        setIsPaletteOpen((open) => {
          if (!open) {
            sendEvent({name: 'commandPaletteOpen'})
          }
          return !open
        })
        e.preventDefault()
      }
    }
    document.addEventListener('keydown', keydownHandler)
    return () => document.removeEventListener('keydown', keydownHandler)
  }, [])

  const hidePalette = () => {
    setIsPaletteOpen(false)
  }

  const shouldRender = () => {
    const {asPath} = router
    if (asPath === '/me') {
      // return typeof user !== 'undefined'
    }
    return true
  }

  const [battle, setBattle] = useState(null)
  const [level, setLevel] = useState(null)
  const [room, setRoom] = useState(null)

  const [streak, setStreak] = useState()
  const [theme, setTheme] = useState(null)

  useEffect(() => {
    if (!theme) {
      return () => {}
    }

    // load light theme for user early on
    if (theme === 'light' && !document.querySelector('#lightThemeLink')) {
      const s = document.createElement('link')
      s.href = '/light-theme.css'
      s.rel = 'stylesheet'
      s.id = 'lightThemeLink'
      document.head.append(s)
    }

    if (theme === 'dark' && document.querySelector('#lightThemeLink')) {
      document.querySelector('#lightThemeLink').remove()
    }
  }, [theme])

  useEffect(() => {
    setTheme(user?.settings?.theme)
  }, [user])

  useEffect(() => {
    if (!user?.uid) return () => {}
    getStreakCountRealtime(user.uid, (data) => {
      const today = new Date()
      const lastPlayedDate = data?.lastPlayed && data?.lastPlayed.toDate()
      const hasPlayedToday =
        lastPlayedDate &&
        lastPlayedDate.getDate() === today.getDate() &&
        lastPlayedDate.getMonth() === today.getMonth() &&
        lastPlayedDate.getFullYear() === today.getFullYear()

      setStreak({count: data?.streak?.count || 0, hasPlayedToday})
    })
  }, [user?.uid])

  useEffect(() => {
    mixpanel.init('cad0db1bfcfef8c55ffed0dc8dc00425', {
      debug: true,
      track_pageview: true,
      persistence: 'localStorage',
    })

    function onRouteChangeComplete() {
      mixpanel.track_pageview()
    }
    // Record a pageview when route changes
    router.events.on('routeChangeComplete', onRouteChangeComplete)

    // Unassign event listener
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }
  }, [])

  useEffect(() => {
    ;(function (w, r) {
      w._rwq = r
      w[r] =
        w[r] ||
        function () {
          ;(w[r].q = w[r].q || []).push(arguments)
        }
    })(window, 'rewardful')
    const script = document.createElement('script')
    script.src = 'https://r.wdfl.co/rw.js'
    script.async = true
    script.dataset.rewardful = 'c4b788'
    document.head.appendChild(script)
  }, [])

  return (
    <UserContext.Provider value={user}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta key="url" property="og:url" content="https://cssbattle.dev" />
        <meta
          key="banner"
          property="og:image"
          content="https://cssbattle.dev/images/banner2.png"
        />
        <meta key="ogTitle" property="og:title" content="CSSBattle" />
        <title key="title">CSSBattle</title>
        <meta
          key="desc"
          property="og:description"
          content="The funnest CSS game for web designers &
          developers"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="shortcut icon" href="/images/logo-square.png" />
        <style id="critical-css" type={isMounted ? 'ruby' : undefined}>
          {`/* CRITICAL CSS */
          body {
            background-color: #171d23;
          }

          body:after {
            font-size: 1.5rem;
            text-align: center;
            font-family: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono',
              'Lucida Console', monospace;
            color: #cbd1e1;
            display: block;
            content: 'Hang on! The battleground is getting setup...';
            width: 400px;
            position: fixed;
            top: 40vh;
            left: calc(50vw - 200px);
            opacity: 0;
            animation: 0.3s show ease forwards;
            animation-delay: 1s;
          }

          @keyframes show {
            to {
              opacity: 1;
            }
          }`}
        </style>
      </Head>

      <GlobalContext.Provider
        value={{
          streak,
          battle,
          setBattle,
          level,
          setLevel,
          theme,
          setTheme,
          room,
          setRoom,
        }}
      >
        <Page>{shouldRender() && <Component {...pageProps} />}</Page>
      </GlobalContext.Provider>

      {isMounted && (
        <CommandPalette
          isOpen={isPaletteOpen}
          onOpenChange={setIsPaletteOpen}
          onRequestClose={hidePalette}
        />
      )}
      <ToastContainer
        position="bottom-right"
        autoClose={3500}
        theme="dark"
        transition={Slide}
      />
      <div id="portal-root"></div>
    </UserContext.Provider>
  )
}

export default MyApp
