import React, {useState, useEffect, useRef} from 'react'
import {getDateDifference} from './utils'
import {Emoji} from './Common'
import {AnimatedNumber} from '@components/AnimatedNumber'
import {HStack, VStack} from '@components/Stack'
import {Text} from '@components/Text'
import {m} from 'framer-motion'

function CountDownPiece({
  count,
  label,
  shortUnits,
  minimal,
  size,
  padLength,
  showUnits,
}) {
  return (
    <VStack gap="0.4rem">
      {minimal ? (
        <span
          className={`countdown__number ${
            shortUnits ? 'countdown__number--small' : ''
          }`}
        >
          {padLength ? count.toString().padStart(padLength, '0') : count}
          {showUnits ? label : null}
          {count === 1 || shortUnits ? '' : 's'}
        </span>
      ) : (
        <>
          <AnimatedNumber countDigits={2} number={count} size={size} />
          {showUnits ? (
            <Text appearance="tertiary" mono={false} transform="capitalize">
              {label}
              {count === 1 || shortUnits ? '' : 's'}
            </Text>
          ) : null}
        </>
      )}
    </VStack>
  )
}

export function CountDown({
  date,
  prefix = '',
  suffix = 'remaining',
  separator = ' : ',
  hideSeconds = false,
  onFinish,
  maxLength = 4,
  shortUnits = false,
  showEmoji = true,
  minimal = false,
  big = false,
  size = 0.2,
  showUnits = true,
  justify = 'flex-start',
}) {
  const [remaining, setRemaining] = useState({})
  const intervalRef = useRef()

  function update() {
    const now = Date.now()

    if (now > new Date(date)) {
      clearInterval(intervalRef.current)

      if (typeof onFinish === 'function') onFinish()
      return
    }
    let diff = getDateDifference(now, date)

    if (maxLength) {
      const parts = ['days', 'hours', 'minutes', 'seconds'].map((key) => {
        return {[key]: diff[key], val: diff[key]}
      })
      // find first non-zero part
      const index = parts.findIndex((part) => part.val)
      // splice all parts before the index
      parts.splice(0, index)
      parts.length = maxLength
      diff = Object.assign({}, ...parts)
    }

    setRemaining(diff)
  }

  useEffect(() => {
    update()

    const interval = (intervalRef.current = setInterval(update, 1000))
    return () => {
      clearInterval(interval)
    }
  }, [date])

  const shouldShowHours = remaining.days || remaining.hours
  const shouldShowMinutes =
    (remaining.days || remaining.hours || remaining.minutes) &&
    remaining.minutes !== undefined
  const shouldShowSeconds =
    !hideSeconds &&
    (remaining.days ||
      remaining.hours ||
      remaining.minutes ||
      remaining.seconds) &&
    remaining.seconds !== undefined

  const isMinimal = shortUnits || minimal

  return (
    <div
      className={`countdown ${
        minimal ? 'countdown--minimal' : 'countdown--primary'
      }
      ${big ? 'countdown--big' : ''}
      `}
      style={{'--size': size}}
    >
      <HStack gap={minimal ? '0.1rem' : 0} align="center" justify={justify}>
        {showEmoji ? <Emoji label="watch" children="⏰" /> : null}{' '}
        {prefix ? ` ${prefix} ` : null}
        {remaining.days ? (
          <>
            <CountDownPiece
              label={`${isMinimal ? 'd' : 'day'}`}
              count={remaining.days}
              shortUnits={isMinimal}
              size={size}
              minimal={minimal}
              showUnits={showUnits}
            />
            {shouldShowHours && (
              <div className="countdown__separator">{separator}</div>
            )}
          </>
        ) : null}{' '}
        {shouldShowHours ? (
          <>
            <CountDownPiece
              label={`${isMinimal ? 'h' : 'hour'}`}
              count={remaining.hours}
              shortUnits={isMinimal}
              minimal={minimal}
              size={size}
              padLength={2}
              showUnits={showUnits}
            />
            {shouldShowMinutes && (
              <div className="countdown__separator">{separator}</div>
            )}
          </>
        ) : null}{' '}
        {shouldShowMinutes ? (
          <>
            <CountDownPiece
              label={`${isMinimal ? 'm' : 'minute'}`}
              count={remaining.minutes}
              shortUnits={isMinimal}
              size={size}
              minimal={minimal}
              showUnits={showUnits}
              padLength={2}
            />
            {shouldShowSeconds && (
              <div className="countdown__separator">{separator}</div>
            )}
          </>
        ) : null}{' '}
        {shouldShowSeconds ? (
          <CountDownPiece
            label={`${isMinimal ? 's' : 'second'}`}
            count={remaining.seconds}
            shortUnits={isMinimal}
            size={size}
            minimal={minimal}
            padLength={2}
            showUnits={showUnits}
          />
        ) : null}
        {suffix ? ` ${suffix}` : null}
      </HStack>
    </div>
  )
}
