import React from 'react'
import Link from 'next/link'
import {ProBadge} from './ProBadge'
import {useUser} from './hooks/useUser'
import {useRouter} from 'next/router'
import {Icon} from './Icon'
import {useWhenMounted} from './hooks/useWhenMounted'
import {HStack} from '@components/Stack'

function Separator() {
  return (
    <span aria-hidden="true" style={{margin: '0 0.8em'}}>
      ·
    </span>
  )
}

function Footer() {
  const year = new Date().getFullYear()
  const isMounted = useWhenMounted()
  const user = useUser()

  return (
    <div className={`v2-footer`}>
      <div className="footer__copyright">© {year} CSSBattle</div>

      <div className="footer__links">
        {/* {user && user.isPro && isMounted ? null : (
          <div style={{display: 'inline-block'}}> */}
        {/* above div is there bcoz of server rendering difference to difference from other <a>" */}
        {/* <Link href="/plus">
              <a style={{display: 'inline-flex', alignItems: 'center'}}>
                <span>Upgrade to</span>{' '}
                <ProBadge style={{marginLeft: '0.5rem'}} />
              </a>
            </Link>
            <Separator />
          </div>
        )} */}
        <HStack gap="1.5rem" justify="flex-end">
          <Link href="/tips">
            <a>Tips & Tricks</a>
          </Link>
          {/* <Separator /> */}

          {/* already in the sidebar */}
          {/* <Link href="/learn">
            <a>Learn CSS</a>
          </Link> */}

          <Link href="/newsletter">
            <a>Newsletter</a>
          </Link>

          {/* <Separator /> */}
          <Link href="/blog">
            <a>Blog</a>
          </Link>
          {/* <Separator /> */}
          <Link href="/faqs">
            <a>FAQs</a>
          </Link>
          {/* <Separator /> */}
          <a
            href="https://community.cssbattle.dev"
            target="_blank"
            rel="external noopener noreferrer"
          >
            Community
          </a>
          {/* <Separator /> */}
          <Link href="/about">
            <a>About</a>
          </Link>
          {/* <Separator /> */}
          <Link href="/advertise">
            <a>Advertise</a>
          </Link>
          {/* <Separator /> */}
          <Link href="/privacy-and-terms">
            <a>Privacy & Terms</a>
          </Link>
          {/* <Separator /> */}
          <a
            href="https://discord.gg/rvqvbcFM86"
            target="_blank"
            rel="external noopener noreferrer"
            className="hint--top-left"
            aria-label="Join on Discord"
          >
            <Icon marginRight="0" name="discord-alt" size="18" />
          </a>
          {/* <Separator /> */}
          <a
            href="https://bsky.app/profile/cssbattle.dev"
            target="_blank"
            rel="external noopener noreferrer"
            className="hint--top-left"
            aria-label="Join on Bluesky"
          >
            <Icon marginRight="0" name="bluesky" size="18" />
          </a>

          <a
            href="https://x.com/css_battle"
            target="_blank"
            rel="external noopener noreferrer"
            className="hint--top-left"
            aria-label="Join on X"
          >
            <Icon marginRight="0" name="twitter" size="18" />
          </a>
          {/* <Separator /> */}
          <a
            href="mailto:contact@cssbattle.dev"
            target="_blank"
            rel="external noopener noreferrer"
            className="hint--top-left"
            aria-label="Contact us"
          >
            <Icon marginRight="0" name="mail" size="18" />
          </a>
        </HStack>
      </div>
    </div>
  )
}

const FooterMemo = React.memo(Footer)
export {FooterMemo as Footer}
