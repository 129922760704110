import confetti from 'canvas-confetti'
import {firebaseApp, PROJECT_ID} from './firebase-init'
import {getStorage, ref, uploadBytes} from 'firebase/storage'
import {env, IMG_CDN_URL} from './constants'
import {ProductEnum, Products} from './products'
import {getPlayerProfile} from './util/db'

// https://davidwalsh.name/javascript-debounce-function
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
  var timeout
  return function () {
    var context = this,
      args = arguments
    var later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

/**
 * Updates the value of an object at given key.
 * @param {object} object Object to udpate
 * @param {string} key key to update. Can be nested like 'a.b.c'
 * @param {*} value Value to set at the given key
 */
export function updateKeyInObject(object, key, value) {
  const split = key.split('.')
  let dest = object
  while (split.length > 1) {
    let key = split.shift()
    // create path if not defined already
    dest[key] = dest[key] || {}
    dest = dest[key]
  }
  dest[split[0]] = value
  return object
}

export function removeEmptyKeys(object) {
  Object.keys(object).forEach((key) => {
    if (object[key] && typeof object[key] === 'object')
      removeEmptyKeys(object[key])
    else if (object[key] === undefined || object[key] === null)
      delete object[key]
  })
  return object
}

export function getFormattedRank(rank, digitCount = 2) {
  return `#${String(rank).padStart(digitCount, '0')}`
  if (rank === 0) {
    return '-'
  }
  var j = rank % 10,
    k = rank % 100
  if (j === 1 && k !== 11) {
    return rank + 'st'
  }
  if (j === 2 && k !== 12) {
    return rank + 'nd'
  }
  if (j === 3 && k !== 13) {
    return rank + 'rd'
  }
  return rank + 'th'
}

export function getSmallAvatarUrl(avatarUrl) {
  return avatarUrl.replace(/\.(\w+)\?alt/, '_64.$1?alt')
}

export function showConfetti(time = 4) {
  var end = Date.now() + time * 1000

  ;(function frame() {
    confetti({
      particleCount: 1,
      startVelocity: 0,
      ticks: 100,
      origin: {
        x: Math.random(),
        // since they fall down, start a bit higher than random
        y: Math.random() - 0.2,
      },
      colors: [
        [
          '#26ccff',
          '#a25afd',
          '#ff5e7e',
          '#88ff5a',
          '#fcff42',
          '#ffa62d',
          '#ff36ff',
        ][~~(Math.random() * 7)],
      ],
    })

    if (Date.now() < end) {
      requestAnimationFrame(frame)
    }
  })()
}

export const timeSince = function (date) {
  if (typeof date !== 'object') {
    date = new Date(date)
  }

  var seconds = Math.floor((new Date() - date) / 1000)
  var intervalType

  var interval = Math.floor(seconds / 31536000)
  if (interval >= 1) {
    intervalType = 'year'
  } else {
    interval = Math.floor(seconds / 2592000)
    if (interval >= 1) {
      intervalType = 'month'
    } else {
      interval = Math.floor(seconds / 86400)
      if (interval >= 1) {
        intervalType = 'day'
      } else {
        interval = Math.floor(seconds / 3600)
        if (interval >= 1) {
          intervalType = 'hour'
        } else {
          interval = Math.floor(seconds / 60)
          if (interval >= 1) {
            intervalType = 'minute'
          } else {
            interval = seconds
            intervalType = 'second'
          }
        }
      }
    }
  }

  if (interval > 1 || interval === 0) {
    intervalType += 's'
  }

  return interval + ' ' + intervalType
}

/**
 * returns an object of type {days, hours, minutes, seconds} remaining between
 * date1 and date2
 * @param {date} date1 start date
 * @param {date} date2 end date
 */
export function getDateDifference(date1, date2) {
  // get total seconds between the times
  let delta = Math.abs(new Date(date2) - new Date(date1)) / 1000

  const days = Math.floor(delta / 86400)
  delta -= days * 86400

  const hours = Math.floor(delta / 3600) % 24
  delta -= hours * 3600

  const minutes = Math.floor(delta / 60) % 60
  delta -= minutes * 60

  const seconds = Math.floor(delta % 60)

  return {days, hours, minutes, seconds}
}

/**
 * Persists the firebase user with a subset of it's keys.
 * @param {object} user User object from firebase
 */
export function persistAuthUserLocally(user) {
  const keys = [
    'uid',
    'displayName',
    'photoURL',
    'isPro',
    'settings',
    'username',
    'userSettings',
  ]
  const obj = {}
  keys.map((key) => (obj[key] = user[key]))
  window.localStorage.setItem('lastUser', JSON.stringify(obj))
}

/**
 * Runs the given plugin on the given code and returns the
 * processed code.
 * @param {object} plugin Editor plugin to run
 * @param {string} code Code to run the plugin on
 */
export function runEditorPlugin(plugin, code) {
  var scriptFn = new Function(
    'code',
    `
  ${plugin.code}

  return run(code);
  `
  )
  const processedCode = scriptFn(code)
  return processedCode
}

/**
 * In place merging of similar activities
 * @param {array} activities List of activities
 */
export function mergeSimilarActivities(activities) {
  function isSame(a, b) {
    return (
      a.data.userId === b.data.userId &&
      a.data.levelId === b.data.levelId &&
      a.data.previousUserId === b.data.previousUserId
    )
  }
  let i = 0

  while (i < activities.length - 1) {
    let j = i

    while (
      j + 1 < activities.length &&
      isSame(activities[i], activities[j + 1])
    ) {
      activities[i].data.previousUserCodeSize =
        activities[j + 1].data.previousUserCodeSize
      j++
    }
    if (i !== j) {
      activities.splice(i + 1, j - i)
    }
    i++
  }
}

/**
 * In place merging of similar submissions
 * @param {array} submissions List of activities
 */
export function mergeSimilarSubmissions(submissions) {
  function isSame(a, b) {
    return a.code.trim() === b.code.trim()
  }
  let i = 0 // base thing

  while (i < submissions.length - 1) {
    let j = i + 1 // with which to compare

    // keep incrementing away from the base
    while (j < submissions.length) {
      if (isSame(submissions[i], submissions[j])) {
        submissions.splice(j, 1)
      } else {
        j++
      }
    }
    i++
  }
}

export function getRandomId(length = 20) {
  const string =
    'abcdefghijklmnopqrstuvqxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  let id = ''
  for (let i = length; i--; ) {
    id += string[~~(Math.random() * string.length)]
  }
  return id
}

export function uploadFile({path, file, metaData = {}}) {
  const storageService = getStorage(firebaseApp)

  return uploadBytes(ref(storageService, path), file, metaData).then(
    (snapshot) => {
      return `https://firebasestorage.googleapis.com/v0/b/${PROJECT_ID}.appspot.com/o/${encodeURIComponent(
        path
      )}?alt=media`
    }
  )
}

export function checkIfCustomBattle(battleId) {
  return battleId.length > 6
  // return battles.findIndex(battle => battle.id === battleId) === -1
}

export function getRedirectLink(url, id) {
  return `https://cssbattle.dev/api/adRedirect?url=${encodeURIComponent(
    url
  )}&sponsorId=${id}`
}

export function rand(a, b) {
  return Math.floor(Math.random() * (b - a + 1)) + a
}

export function getChallengeText({
  score,
  codeSize,
  levelId,
  levelName,
  date,
  isDaily = false,
  streak = 1,
}) {
  // list of random challenge tweets
  const regularTargetTexts = [
    `I am on ${score} score with just ${codeSize} characters on Target ${levelId}. Do you have what it takes to beat that? 😎`,
    `Just scored ${score} on Target ${levelId}! Join me in the best ever CSS game ⚔️`,
    `I recreated Target ${levelId} in just ${codeSize} bytes of CSS! Having fun in this crazy CSS game!`,
    `I just finished a challenge on Target ${levelId} with ${score} score! Who can do better?`,
    `Want to have fun with CSS while learning? Beat my score of ${score} on Target ${levelId} in CSSBattle! 🔥`,
    `CSS art FTW! I created Target ${levelId} on CSSBattle in just ${codeSize} characters!! 🤩 Beat that!`,
    `Scored ${score} points on Target ${levelId} of CSSBattle - where CSS and fun meets! Who's up for a challenge?`,

    `woah! I achieved ${score} score on Target ${levelId}? It's time to show off your CSS skills! 😏`,
    `Witness my CSS prowess! I conquered Target ${levelId} with an epic score of ${score}! Dare to challenge me? 💪`,
    `Who's ready to level up? I reached a mind-blowing score of ${score} on Target ${levelId}! Join me and let's battle it out in CSS! 💥`,
    `Unleashing my CSS mastery! Just achieved an impressive score of ${score} on Target ${levelId}! Can you surpass me? 🚀`,
    `Calling all CSS artists! I cracked Target ${levelId} with a score of ${score}! Join the battle and prove your mettle! ⚡`,
    `Best website for CSS artists! Join me on Target ${levelId} and beat my score of ${score}`,
  ]

  const dailyTargetTexts = [
    `%7B⚔️%7D Day ${
      streak || 1
    } of %23CSSBattleDaily streak:\n ${date}  — ${score} score / ${codeSize} chars`,
  ]

  return isDaily
    ? dailyTargetTexts[~~(Math.random() * dailyTargetTexts.length)]
    : regularTargetTexts[~~(Math.random() * regularTargetTexts.length)]
}

export function getTweet({url, text, hashTags}) {
  return `https://x.com/share?url=${url}&text=${text}&hashtags=${hashTags}&via=css_battle&related=css_battle`
}
export function getBlueskyPost({url, text, hashTags}) {
  return `https://bsky.app/intent/compose?text=${encodeURIComponent(
    text
  )}%20${encodeURIComponent(url)}%20via%20%40cssbattle.dev`
}

/**
 * This function will create a new highscore object based on the current and new score data
 * received from backend
 * @param {*} currentObj current highscore object
 * @param {*} data Score data from backend
 * @returns the new highscore object and a boolean that tells if the new score is a highscore
 */
export function createTargetHighScoreObject(currentObj, data) {
  let newObj = currentObj || {}
  let isAHighScore = false
  const isCss = data.validationTags?.includes('css')

  if (isCss) {
    isAHighScore = !currentObj?.score || data.score > currentObj.score
    if (isAHighScore) {
      newObj = {
        ...newObj,
        ...data,
      }
    }
  }
  if (!isCss) {
    isAHighScore = !currentObj?.nonCss || data.score > currentObj.nonCss.score
    if (isAHighScore) {
      newObj = {
        ...newObj,
        nonCss: data,
      }
    }
  }
  if (newObj.nonCss) {
    // if there is a nonCss score, then we need to calculate the overall score
    const isNonCssMore = newObj.nonCss.score > (newObj.score || 0)
    newObj.overall = {
      score: isNonCssMore ? newObj.nonCss.score : newObj.score,
      codeSize: isNonCssMore ? newObj.nonCss.codeSize : newObj.codeSize,
    }
  }

  return {
    newObj,
    isAHighScore,
  }
}

export function getBattleSolveTime(battle, lastUpdatedTime) {
  const {startDate} = battle
  // startDate is string for official battles
  let startDateMillis =
    typeof startDate === 'string' ? +new Date(startDate) : startDate.toMillis()
  let lastUpdatedMillis = lastUpdatedTime.toMillis
    ? lastUpdatedTime.toMillis()
    : lastUpdatedTime._seconds * 1000

  return (lastUpdatedMillis - startDateMillis) / 1000
}

export function getUserTimeZone() {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return timezone
}

export function processStreakLeaderboard(leaderboard) {
  // this should set same rank for same streaks
  let i = 0
  let rank = 1
  while (i < leaderboard.length - 1) {
    let j = i
    leaderboard[i].rank = rank
    while (
      j + 1 < leaderboard.length &&
      leaderboard[i].streak?.count === leaderboard[j + 1].streak?.count
    ) {
      leaderboard[j + 1].rank = leaderboard[i].rank
      j++
    }
    i = j + 1
    rank++
  }

  // find out how many players can be shown on top 3 podium
  // we show podium only for following rank cases:
  let podiumCount = 0
  if (leaderboard[3]?.rank === 4 || !leaderboard[3]?.rank) {
    // Ranks: 1, 2, 3, 4
    podiumCount = 3
  } else if (
    // Ranks: 1, 2, 2, 3
    leaderboard[1].rank === 2 &&
    leaderboard[2].rank === 2 &&
    leaderboard[3].rank === 3
  ) {
    podiumCount = 3
  } else if (
    leaderboard[1].rank === leaderboard[2].rank ||
    leaderboard[2].rank === leaderboard[3].rank
  ) {
    // Ranks: 1, 2, 2, 2 or 1, 2, 3, 3
    podiumCount = 1
  }

  return podiumCount
}

/**
 * Convert any date-ish string/obj to human readable form -> Jul 02, 2021
 * @param {string?object} date date to be formatted
 * @returns string
 */
export function getHumanReadableDate(
  date,
  {showTime = true, utc = false} = {}
) {
  if (!date) return ''
  let d = typeof date.toDate === 'function' ? date.toDate() : new Date(date)
  if (utc) {
    d = new Date(
      Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours())
    )
  }

  let options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }
  if (showTime) {
    options = {
      ...options,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    }
  }
  const dateTimeString = d.toLocaleString(false, options)
  return dateTimeString
}

/**
 * COnvert firestore timestamp to UTC date string for daily targets
 * timestamp -> Jul 02
 * @param {FirestoreTimestamp} date date to be formatted
 * @returns string
 */
export function getFormattedDateForDailyTarget(date) {
  const d = date.toDate()
  const day = d.getUTCDate()
  const month = d.getUTCMonth()
  const monthName = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ][month]

  return `${monthName} ${day}`
}

export function debugLog(...args) {
  if (env === 'development' || window?.location?.href.match(/localhost/)) {
    console.log(...args)
  }
}

export function isMobile() {
  return (
    typeof window !== 'undefined' &&
    window.matchMedia('(max-width: 600px)').matches
  )
}

export function cdnifyStorageUrl(url) {
  return url.replace(/https.*appspot.com\/o\//, IMG_CDN_URL)
}

export function getBattleCreditTypeFromDuration(durationInMinutes) {
  if (
    durationInMinutes <= Products[ProductEnum.CUSTOM_BATTLE_MINI].maxDuration
  ) {
    return 0
  }
  if (
    durationInMinutes <= Products[ProductEnum.CUSTOM_BATTLE_SMALL].maxDuration
  ) {
    return 1
  }
}

export async function getPlayersList({playerIds, playersList, user}) {
  // instead of spreading old 'playersList' in 'list' variable, we need to filter
  // since when someone leaves, we need to remove them from list too, spreading will not remove those players
  let list = playersList.filter((player) => playerIds.includes(player.uid))

  // to add current user
  const currentUserIndex = list.findIndex((player) => player.uid === user.uid)
  // if user in list, we add user to start of list
  if (currentUserIndex !== -1) {
    const currentUser = list[currentUserIndex]
    list.splice(currentUserIndex, 1) // to delete current user from the list
    list = [currentUser, ...list]
  }
  // if not in list, we add new entry at start
  else {
    list = [user, ...list]
  }

  // fetching only profiles, which are not in list
  const playersData = await Promise.all(
    playerIds
      .filter((id) => !list.some((player) => player.uid === id))
      .map((id) => getPlayerProfile(id))
  )

  for (const data of playersData) {
    list.push({
      uid: data.userId,
      photoURL: data?.avatar,
      ...data,
    })
  }

  return list
}

export function playSfx(sfx, delay = 0) {
  const a = new Audio(sfx.match(/\.\w+$/) ? sfx : `/sfx/${sfx}.mp3`)
  setTimeout(() => {
    a.play()
  }, delay * 1000)
}

export function parseSearchTerms(input) {
  const terms = []
  let currentTerm = ''
  let inQuotes = false

  for (let i = 0; i < input.length; i++) {
    const char = input[i]

    if (char === '"') {
      inQuotes = !inQuotes
      currentTerm += char
    } else if (char === ',' && !inQuotes) {
      if (currentTerm.trim()) {
        terms.push(currentTerm.trim())
      }
      currentTerm = ''
    } else {
      currentTerm += char
    }
  }

  // Add the last term if it's not empty
  if (currentTerm.trim()) {
    terms.push(currentTerm.trim())
  }

  // Remove quotes from terms
  return terms.map((term) => term.replace(/^"(.*)"$/, '$1'))
}

export function getCurrentDayUTCStartDate() {
  const currentLocalDate = new Date()
  const utcDate = new Date(
    currentLocalDate.toLocaleString('en-US', {timeZone: 'UTC'})
  )
  const startDate = new Date(
    Date.UTC(
      utcDate.getFullYear(),
      utcDate.getMonth(),
      utcDate.getDate(),
      0,
      0,
      0
    )
  )
  return startDate
}
